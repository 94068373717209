
const REACT_URL = process.env.VUE_APP_REACT_URL;

import Vue from "vue";
export default Vue.extend({
  name: "Iframe",
  data() {
    return {
      user: null,
      token: null
    } as any;
  },
  mounted() {
    this.requestUserData();
    window.addEventListener("message", this.handleMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
    window.removeEventListener("resize", this.sendHeightToParent);
  },
  methods: {
    requestUserData() {
      window.parent.postMessage({ type: "REQUEST_USER_DATA" }, REACT_URL);
    },
    handleMessage(event: any) {
      // Validate the origin of the message
      if (event.origin !== REACT_URL) {
        return;
      }

      console.log("event", event);

      if (event.data.type === "RECEIVE_USER_DATA") {
        const { token, url } = event.data;
        this.$store.dispatch("auth/LOGIN_USING_IFRAME", { token }).then(() => {
          console.log("Login successful", url);
          this.$router.push(url).then(() => {
            this.overrideRouter();
            setTimeout(() => {
              this.sendHeight();
            }, 2000);
          });
        });
      }
    },
    sendHeight() {
      const height = document.getElementById("app")?.scrollHeight;
      console.log("height", height);
      window.parent.postMessage({ type: "HEIGHT_CHANGE", height }, REACT_URL);
    },
    overrideRouter() {
      // Store the original push method
      // const originalPush = this.$router.push;

      // Override the push method
      //@ts-ignore
      this.$router.push = (...args: any) => {
        // Call the original push method
        // originalPush.apply(this.$router, args);

        // Send the route to the parent React app
        console.log(" this.$router.currentRoute", args, this.$router);
        const height = document.body.scrollHeight;
        console.log("height", height);

        if (args[0] && typeof args[0] === "string") {
          window.parent.postMessage(
            { type: "ROUTE_CHANGE", route: args[0] },
            REACT_URL
          );
        } else if (args[0] && typeof args[0] === "object") {
          window.parent.postMessage(
            { type: "ROUTE_CHANGE", route: args[0].path },
            REACT_URL
          );
        }
      };
    }
  }
});
